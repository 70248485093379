import { createApp, reactive } from 'vue';
import { plugin, defaultConfig } from '@formkit/vue';
import { applicationIcons } from '@formkit/icons';

import App from './App.vue';
import router from './router';
import store from './store';
import formkitConfig from './formkit.config';
import './index.css';

import ClientError from './error/ClientError';
import NetworkError from './error/NetworkError';

const errorList = reactive([]);

// eslint-disable-next-line
const timer = (time) => new Promise((resolve, _) => {
  setTimeout(() => {
    resolve();
  }, time);
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(plugin, defaultConfig({
    ...formkitConfig,
    icons: {
      ...applicationIcons,
    },
  }));

// Error Handling
// eslint-disable-next-line
app.config.errorHandler = async (err, vm, info) => {
  console.error(err);

  if (err instanceof NetworkError) {
    // Push the error onto the error queue
    errorList.push(err);
  }

  if (err instanceof ClientError) {
    // Push the error onto the error queue
    errorList.push({
      message: err.message,
      title: 'Client Error',
    });
  }

  if (err instanceof NetworkError || err instanceof ClientError) {
    // Wait 10 seconds
    await timer(10000);

    // All potential errors before this one have already been dequeued. Dequeue this error
    errorList.shift();
  }
};

// Provide the error array to the child
app.provide('errorList', errorList);

/*
 * Stripe Configuration
 */

const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
const stripe = window.Stripe(stripePublicKey);

app.config.globalProperties.$stripe = stripe;

app.mount('#app');
