<template>
  <div class="bg-white rounded w-64 text-left px-4 py-2">
    <div class="flex">
      <p class="text-lg">{{title}}</p>
      <!-- Spacing Box -->
      <div class="flex-1"></div>
      <Icon icon="close" iconClass="hover:bold" class="cursor-pointer" @click="handleClick"/>
    </div>
    <hr/>
    <p class="text-md">{{message}}</p>
    <p class="text-sm text-grey" v-if="code != ''">{{code}}</p>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description A message that is displayed to the user informing them of the error that they
 * have encountered
 * 12/28/22
 */
import Icon from '@/components/Icon.vue';

export default {
  name: 'ErrorMessage',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: 'Error',
      required: false,
    },
    message: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      iconClass: '',
    };
  },
  methods: {
    handleClick() {
      this.$emit('close');
    },
  },
};
</script>
