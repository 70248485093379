<template>
  <div
    class="animate-spin p-2 w-4 h-4 rounded-full border-2 border-l-transparent
      border-b-transparent border-inherit"
    :class="color ? `border-${color}` : ''"
  >
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description A loading icon to be shown on a button
 * 3/23/23
 */

export default {
  name: 'ButtonLoadingSpinner',
  props: {
    color: {
      type: String,
      default: 'white',
      required: false,
    },
  },
};
</script>
