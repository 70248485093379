<template>
  <div class="relative w-48 h-48">
    <p
      class="
        text-viridian100 font-shrikhand absolute block w-full
        left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-4xl"
    >GG</p>
    <div
      v-for="n in 8"
      :key="`circle-${n}`"
      class="
        absolute block
        left-2/4 top-2/4 -m-2"
      :class="`rotate-${45 * ((n - 1) % 2)}`"
    >
      <div
        class="bg-viridian100 w-4 h-4 rounded-full"
      :class="`${translations[Math.floor((n - 1) / 2)]} ${opacities[((n + 7) - firstCircle) % 8]}`"
      >
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description A basic loading icon
 * @created 2/18/23
 */
export default {
  name: 'LoadingIcon',
  props: {
    size: {
      required: false,
      type: String,
      default: 'xl',
    },
  },
  data() {
    return {
      index: 0,
      translations: [
        'translate-x-12',
        'translate-y-12',
        '-translate-x-12',
        '-translate-y-12',
      ],
      opacities: [
        'opacity-0',
        'opacity-[.14]',
        'opacity-[.28]',
        'opacity-[.42]',
        'opacity-[.57]',
        'opacity-[.71]',
        'opacity-[.85]',
        'opacity-1',
      ],
      firstCircle: 0,
    };
  },
  created() {
    setInterval(() => {
      if (this.firstCircle === 7) {
        this.firstCircle = 0;
        return;
      }

      this.firstCircle += 1;
    }, 100);
  },
};
</script>
