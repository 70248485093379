<template>
  <div class="space-y-4">
    <div v-if="!showAddCard" class="space-y-4">
      <div>
        <h2 class="text-lg">Payment Methods</h2>
        <p>Which card would you like to charge monthly?</p>
      </div>
      <div class="space-y-2" data-cy="credit-card-list">
        <div
          v-if="paymentMethods.cards.length === 0"
          class="flex justify-center bg-whitesmoke80 rounded-lg p-2 flex items-center border-2
            border-whitesmoke60"
          >
          <p>No Cards</p>
        </div>
        <div
          v-for="card in paymentMethods.cards" :key="card.paymentMethodId"
          data-cy="credit-card-option"
          class="bg-whitesmoke80 rounded-lg p-2 flex items-center border-2 shadow"
          :class="chosenCard === card ? 'border-viridian80 border-4' : 'border-whitesmoke60'"
          @click="() => chosenCard = card"
        >
          <StylizedRadioButton :selected="chosenCard === card"/>

          <div class="flex-1">
            <p>{{ card.card.cardholderName }}</p>
            <p><b>**** **** **** {{ card.card.last4 }}</b>
            {{ formatExpiry(card) }} </p>
          </div>

          <img
            v-if="card.card.brand === 'visa'"
            src="@/assets/lightCards/visa.png"
            alt="Visa"
            class="w-16 rounded"
          >
          <img
            v-if="card.card.brand === 'amex'"
            src="@/assets/lightCards/amex.png"
            alt="American Express"
            class="w-16 rounded"
          >
          <img
            v-if="card.card.brand === 'mastercard'"
            src="@/assets/lightCards/mastercard.png"
            alt="Mastercard"
            class="w-16 rounded"
          >
          <img
            v-if="card.card.brand === 'discover'"
            src="@/assets/lightCards/discover.png"
            alt="Discover"
            class="w-16 rounded"
          >
          <img
            v-if="card.card.brand === 'diners'"
            src="@/assets/lightCards/dinersClubInternational.png"
            alt="Diners Club"
            class="w-16 rounded"
          >
          <img
            v-if="card.card.brand === 'jcb'"
            src="@/assets/lightCards/jcb.png"
            alt="JCB"
            class="w-16 rounded"
          >
        </div>
        <div class="flex justify-end">
          <button
            class="bg-viridian100 text-whitesmoke100 rounded-full px-4 py-1 hover:bg-viridian80"
            @click="() => showAddCard = true"
          >Add Card</button>
        </div>
        <p>Monthly Cost: <b>${{ (subscription.price / 100).toFixed(2) }}</b></p>
        <FormKit
          type="button"
          :label="`Subscribe to ${subscription.name}`"
          @click="subscribeUser"
        >
          <template #prefix>
            <ButtonLoadingSpinner
              v-if="loadingCreateSubscription"
              class="mr-2 -ml-2"
              color="white"
            />
          </template>
        </FormKit>
      </div>
    </div>
    <div v-if="showAddCard">
      <AddCreditCard
        @refresh="$emit('refresh')"
      />
      <button
        class="rounded-full px-4 py-2 bg-red-500 text-whitesmoke100"
        @click="() => showAddCard = false"
      >Cancel</button>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Allows a user to add a subscription
 * 3/21/23
 */

import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { payment } from '@nicballesteros/golf-guru-client';

import StylizedRadioButton from '@/components/StyledRadioButton.vue';
import ButtonLoadingSpinner from '@/components/ButtonLoadingSpinner.vue';

import NetworkError from '@/error/NetworkError';
import ClientError from '@/error/ClientError';

import AddCreditCard from './AddCreditCard.vue';

export default {
  name: 'CreateSubscription',
  emits: ['closeModal', 'refresh', 'error'],
  components: {
    ButtonLoadingSpinner,
    StylizedRadioButton,
    AddCreditCard,
  },
  props: {
    paymentMethods: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'token',
    ]),
  },
  methods: {
    async subscribeUser() {
      try {
        if (this.chosenCard === undefined) {
          return;
        }

        this.loadingCreateSubscription = true;

        await payment.createSubscription(
          this.token,
          'Card',
          this.chosenCard.id,
          this.subscription.id,
        );

        this.loadingCreateSubscription = false;

        this.refreshTokens();
        this.$router.push('/subscriptions');
      } catch (err) {
        console.error(err);

        this.loadingCreateSubscription = false;
        this.$emit('closeModal');

        if (err instanceof NetworkError) {
          throw err;
        }

        throw ClientError('Could not subscribe. Please try again');
      }
    },
    formatExpiry(card) {
      const { expMonth, expYear } = card.card;

      let monthString = `${expMonth}`;
      const yearString = `${expYear}`.substring(2, 4);

      if (monthString.length === 1) {
        monthString = `0${monthString}`;
      }

      return `${monthString}/${yearString}`;
    },
    ...mapActions({
      refreshTokens: 'refresh',
    }),
  },
  setup(props) {
    return {
      chosenCard: ref(props.paymentMethods.cards[0]),
      showAddCard: ref(false),
      loadingCreateSubscription: ref(false),
    };
  },
};
</script>
