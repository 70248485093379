<template>
  <div>
    <FormKit
      label="Street"
      type="text"
      validation="required|length:5,200"
      name="street"
    />
    <FormKit
      label="Street 2"
      type="text"
      name="street2"
      validation="length:0,50"
    />
    <div class="flex gap-2">
      <FormKit
        label="City"
        type="text"
        validation="required|length:3,50"
        name="city"
      />
      <FormKit
        label="State"
        type="text"
        validation="required|length:2,2|matches:/[A-Z]/"
        name="state"
      />
    </div>
    <div class="flex gap-2">
      <FormKit
        label="Zip Code"
        type="text"
        validation="required|length:5,5|matches:/[0-9]/"
        name="zipCode"
      />
      <FormKit
        label="Country"
        type="text"
        placeholder="USA"
        validation="required|length:2,2|matches:/^US$/"
        value="US"
        disabled
        name="country"
      />
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Retrieves the Billing Address from a user
 * 3/21/23
 */
export default {
  name: 'BillingAddressForm',
};
</script>
