<template>
  <div
    id="card-element"
    class="w-64 rounded-md border p-2 border-black"
  ></div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Wraps the StripeElementCard
 * 10/13/23
 */

export default {
  name: 'StripeCardInput',
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleCardInputChanges(event) {
      this.context.node.input({
        cardElement: this.cardElement,
        validation: event.complete,
        empty: event.empty,
      });
    },
  },
  data() {
    const elements = this.$stripe.elements();

    return {
      cardElement: elements.create('card'),
    };
  },
  mounted() {
    this.cardElement.mount('#card-element');

    this.cardElement.on('change', this.handleCardInputChanges);
  },
};
</script>
