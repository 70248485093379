<template>
  <div
    class="flex justify-around py-4 px-2 sm:p-4"
    :class="showBackground ? 'bg-gunmetal100 text-whitesmoke100' : 'text-viridian100'"
  >
    <div class="flex-1">
      <router-link to="/"><a>
        <div
          class="hover:cursor-pointer flex max-w-fit mx-auto justify-center"
          :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
        >
          <img
            src="@/assets/logoSuite/GG_Primary Logo Full Color.svg"
            alt="Golf Guru Logo"
            class="w-32 h-16"
            :class="showBackground ? 'bg-whitesmoke100 rounded-lg px-2 me-2' : ''"
          >
          <p class="font-lato font-bold">Beta</p>
        </div>
      </a></router-link>
    </div>
    <div class="flex-1 sm:block hidden"></div>
    <div
      v-if="!loggedIn"
      class="flex-2 sm:flex-1 flex justify-center gap-2 md:gap-3 lg:gap-4 items-center font-lato"
    >
      <router-link to="/">
        <a
          class="sm:text-lg text-base hover:cursor-pointer"
          :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
          data-cy="home"
        >Home</a>
      </router-link>

      <router-link to="/login">
        <a
          class="sm:text-lg text-base hover:cursor-pointer"
          :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
          data-cy="login"
        >Login</a>
      </router-link>

      <router-link to="/register">
        <a
          class="sm:text-lg text-base hover:cursor-pointer"
          :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
          data-cy="register"
        >Register</a>
      </router-link>
    </div>
    <div
      v-if="loggedIn"
      class="flex-2 sm:flex-1 flex justify-center gap-2 md:gap-3 lg:gap-4 items-center font-lato"
    >
      <router-link to="/dashboard">
        <a
          v-if="loggedIn"
          class="text-base sm:text-lg hover:cursor-pointer"
          :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
          data-cy="dashboard"
        >Dashboard</a>
      </router-link>

      <a
        class="text-base sm:text-lg hover:cursor-pointer"
        :class="showBackground ? 'hover:text-whitesmoke80' : 'hover:text-gunmetal60'"
        data-cy="profile"
        @click="handleProfileClick"
      >Profile</a>
    </div>
    <Profile
      v-if="loggedIn"
      v-show="showProfile"
      ref="profile"
      class="sm:mt-2"
      data-cy="profile-component"
    />
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description A header for the app.
 */

import { mapGetters } from 'vuex';

import Profile from './Profile.vue';

export default {
  name: 'HeaderComponent',
  components: {
    Profile,
  },
  props: {
    showBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    profileElement() {
      return this.$refs.profile.$el;
    },
    ...mapGetters({
      loggedIn: 'isLoggedIn',
    }),
  },
  methods: {
    handleHomeClick() {
      this.$router.push('/');
    },
    handleLoginClick() {
      this.$router.push('/login');
    },
    handleRegisterClick() {
      this.$router.push('/register');
    },
    handleDashboardClick() {
      this.$router.push('/dashboard');
    },
    handleNotificationsClick() {
      this.$router.push('/notifications');
    },
    handleProfileClick() {
      this.showProfile = !this.showProfile;
    },
  },
  data() {
    return {
      showProfile: false,
    };
  },
};
</script>
