<template>
  <div class="font-lato w-screen">
    <HomeLanding/>
    <div class="text-whitesmoke100 w-screen">
      <HomeLiveScoring
        id="live-scoring"
        :season="season"
      />
      <HomeSeasons
        id="seasons"
        @season="(seasonData) => season = seasonData"
      />
      <HomePricing id="pricing"/>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @module HomePage
 * @description The home page.
 */

import Footer from '@/components/Footer.vue';

import HomeLanding from './HomeLanding.vue';
import HomeLiveScoring from './HomeLiveScoring.vue';
import HomeSeasons from './HomeSeasons/HomeSeasons.vue';
import HomePricing from './HomePricing.vue';

export default {
  name: 'HomePage',
  components: {
    HomeLanding,
    HomeLiveScoring,
    HomeSeasons,
    HomePricing,
    FooterComponent: Footer,
  },
  data() {
    return {
      season: undefined,
    };
  },
};
</script>
