import ErrorManifest from './error-manifest.json';

const parseCode = (code) => {
  // Get the first 3 numbers from the error code
  const str = `${code}`.substring(0, 3);

  const errorCodes = ErrorManifest[str];

  const errorCode = errorCodes.find((element) => element.code === code);

  if (errorCode === undefined) {
    return '';
  }

  return errorCode.message;
};

class NetworkError extends Error {
  constructor(err, status, code, title) {
    const message = parseCode(code);
    super(message);

    // Keep track of the original error to be able to debug later on
    this.err = err;

    this.status = status;
    this.code = code;
    this.title = title ?? `Error: ${err.response.statusText}`;

    this.name = 'NetworkError';
  }
}

export default NetworkError;
