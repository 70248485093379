// Alias for error. Used to distinguish between hidden error and a displayed error
class ClientError extends Error {
  constructor(message) {
    super(message);

    this.name = 'ClientError';
  }
}

export default ClientError;
