<template>
  <div>
    <FormKit type="form" :actions="false" @submit="(data) => addCard(data.multiStep)">
      <FormKit type="multi-step" name="multiStep" :allowIncomplete="false">
        <FormKit type="step" name="card" label="Card">
          <StripeCreditCardForm/>
        </FormKit>
        <FormKit type="step" name="billingAddress" label="Billing Address">
          <BillingAddressForm />
          <template v-slot:stepNext>
            <FormKit type="submit"/>
          </template>
        </FormKit>
      </FormKit>
    </FormKit>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Adds a credit card to the system
 * 3/21/23
 */

import { mapGetters } from 'vuex';
import { payment } from '@nicballesteros/golf-guru-client';

import ClientError from '@/error/ClientError';
import NetworkError from '@/error/NetworkError';

import StripeCreditCardForm from './StripeCreditCardForm.vue';
import BillingAddressForm from './BillingAddressForm.vue';

export default {
  name: 'AddCreditCard',
  emits: ['refresh'],
  components: {
    BillingAddressForm,
    StripeCreditCardForm,
  },
  computed: {
    ...mapGetters([
      'token',
    ]),
  },
  methods: {
    async addCard(payload) {
      try {
        const address = payload.billingAddress;

        const { paymentMethod } = await this.$stripe.createPaymentMethod({
          type: 'card',
          card: payload.card.card.cardElement,
          billing_details: {
            name: payload.card.cardholderName,
            address: {
              city: address.city,
              country: address.country,
              line1: address.street,
              line2: address.street2,
              postal_code: address.zipCode,
              state: address.state,
            },
          },
        });

        await payment.attachPaymentCard(this.token, paymentMethod.id);

        this.$emit('refresh');
      } catch (err) {
        console.error(err);

        if (err instanceof NetworkError) {
          throw err;
        }

        throw new ClientError('Could not add credit card to your file');
      }
    },
  },
};
</script>
