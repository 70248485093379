import { generateClasses } from '@formkit/themes';
import { genesisIcons } from '@formkit/icons';
import genesis from '@formkit/themes/tailwindcss/genesis';
import { createMultiStepPlugin } from '@formkit/addons';
import '@formkit/addons/css/multistep';

export default {
  icons: {
    ...genesisIcons,
  },
  config: {
    classes: {
      ...generateClasses(genesis),
    },
  },
  plugins: [
    createMultiStepPlugin(),
  ],
};
