import { createStore } from 'vuex';
import router from '@/router';

import GolfGuruAuthClient, { client, user as userAPI } from '@nicballesteros/golf-guru-client';

import ClientError from '@/error/ClientError';
import NetworkError from '@/error/NetworkError';

const authClient = new GolfGuruAuthClient({
  userPoolId: process.env.VUE_APP_USER_POOL,
  userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
});

const authStore = createStore({
  state: {
    isLoggedIn: localStorage.getItem('idToken') !== null,
    idToken: localStorage.getItem('idToken') || '',
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    status: '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    error: new Error(''),
    following: localStorage.getItem('following') ? JSON.parse(localStorage.getItem('following')) : {
      players: [],
      coaches: [],
      teams: [],
      events: [],
    },
  },
  getters: {
    token(state) {
      return state.idToken;
    },
    accessToken(state) {
      return state.accessToken;
    },
    status(state) {
      return state.status;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    user(state) {
      if (!state.user) {
        return undefined;
      }

      return {
        id: state.user.sub,
        name: `${state.user.given_name} ${state.user.family_name}`,
        email: state.user.email,
        groups: state.user.groups,
      };
    },
    refresh(state) {
      return state.refreshToken;
    },
    error(state) {
      return state.error;
    },
    authClient() {
      return authClient;
    },
    following(state) {
      return state.following;
    },
  },
  mutations: {
    setIdToken(state, token) {
      state.idToken = token;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setIsLoggedIn(state, loggedIn) {
      state.isLoggedIn = loggedIn;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    setError(state, error) {
      state.error = error;
    },
    setFollowing(state, following) {
      state.following = following;
      localStorage.setItem('following', JSON.stringify(following));
    },
  },
  actions: {
    async login({ commit, dispatch }, { email, password }) {
      try {
        // Set status as loading
        commit('setStatus', 'loading');
        dispatch('logout');

        const { isSignedIn, nextStep } = await authClient.login({
          email,
          password,
        });

        if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          router.push({
            path: '/confirm',
            query: {
              email,
            },
          });
          return;
        }

        if (!isSignedIn) {
          throw new Error('No user found or Password is incorrect');
        }

        if (nextStep.signInStep !== 'DONE') {
          throw new Error('Could not sign in user');
        }

        const [
          attributes,
          { idToken, accessToken },
          groups,
        ] = await Promise.all([
          authClient.getAttributes(),
          authClient.getTokens(),
          authClient.getUserGroups(),
        ]);

        const userId = attributes.sub;

        const { data: userData } = await userAPI.getUser(`user:${userId}`);

        commit('setFollowing', userData.user.following);

        // Set state with given tokens
        commit('setUser', {
          ...attributes,
          groups,
        });
        commit('setIdToken', idToken);
        commit('setAccessToken', accessToken);
        commit('setIsLoggedIn', true);

        commit('setStatus', 'success');

        router.push('/dashboard');

        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
      } catch (err) {
        console.error(err);

        dispatch('logout');
        commit('setStatus', 'error');

        if (err instanceof NetworkError) {
          commit('setError', err);
          return;
        }

        commit('setError', new ClientError(err.message));
      }
    },
    logout({ commit }) {
      // Reset state
      commit('setIdToken', '');
      commit('setAccessToken', '');
      commit('setRefreshToken', '');
      commit('setIsLoggedIn', false);
      commit('setUser', {});
      commit('setStatus', '');

      localStorage.clear();
    },
    async register({ commit }, {
      email,
      password,
      givenName,
      familyName,
    }) {
      commit('setStatus', 'loading');

      try {
        const user = await authClient.signUp({
          email,
          password,
          firstName: givenName,
          lastName: familyName,
        });

        commit('setUser', user);
        commit('setStatus', 'success');
      } catch (err) {
        console.error(err);
        commit('setStatus', 'error');

        if (err instanceof NetworkError) {
          commit('setError', err);
          return;
        }

        commit('setError', new ClientError('Could not register user'));
      }
    },
    async confirm({ commit }, { email, code, group }) {
      commit('setStatus', 'loading');

      try {
        await authClient.confirm({
          email,
          code,
          group,
        });

        commit('setStatus', 'success');

        router.push('/dashboard');
      } catch (err) {
        console.error(err);
        commit('setStatus', 'error');

        if (err.name === 'ExpiredCodeException') {
          commit(
            'setError',
            new ClientError('The code provided is expired. A new one was sent to your email.'),
          );
          await authClient.resendVerification({
            email,
          });
          return;
        }

        if (err instanceof NetworkError) {
          commit('setError', err);
          return;
        }

        commit('setError', new ClientError('Could not confirm user'));
      }
    },
    async refresh({ commit, getters }) {
      const { isLoggedIn } = getters;

      if (!isLoggedIn) {
        return;
      }

      const { idToken, accessToken } = await authClient.refresh();

      commit('setIdToken', idToken);
      commit('setAccessToken', accessToken);

      localStorage.setItem('idToken', idToken);
      localStorage.setItem('accessToken', accessToken);
    },
    async refreshUserAttributes({ commit }) {
      const [
        attributes,
        groups,
      ] = await Promise.all([
        authClient.getAttributes(),
        authClient.getUserGroups(),
      ]);

      const userId = attributes.sub;

      const { data: userData } = await userAPI.getUser(`user:${userId}`);

      commit('setFollowing', userData.user.following);
      commit('setUser', {
        ...attributes,
        groups,
      });
    },
  },
  modules: {},
});

// Define intercepting an expired token error
client.interceptors.response.use((res) => res, async (err) => {
  const { response, config } = err;

  console.log(err);

  if (response?.status === 401 && response?.data?.message === 'The incoming token has expired') {
    // Refresh the token if the access token is expired
    await authStore.dispatch('refresh');

    // Set the new access token
    config.headers.Authorization = authStore.getters.token;
    return client.request(config);
  }

  if (response?.status >= 400 && response?.data.code !== undefined) {
    const { data, status, error } = response;

    return Promise.reject(new NetworkError(err, status, data.code, error));
  }

  return Promise.reject(err);
});

export default authStore;
