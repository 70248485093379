<template>
  <div @click="handleShowModal">
    <slot :price="formatPrice(subscription.price)"></slot>

    <Teleport to="body">
      <div v-if="showModal">
        <div class="fixed z-40 top-0 left-0 bg-black opacity-50 w-screen h-screen"></div>
        <div
          class="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-whitesmoke60
            bg-whitesmoke100 opacity-100 text-gunmetal100 p-4 rounded-lg space-y-2 border-2
            max-sm:min-w-full overflow-y-auto max-h-screen"
        >
          <div class="flex items-center gap-4">
            <h1 class="text-2xl font-bold text-left">{{ subscription.name }}</h1>
            <div class="flex-1 flex justify-end">
              <button
                class="bg-red-500 rounded w-8 h-8 flex justify-center items-center"
                @click="() => showModal = false"
              ><Icon icon="close" class="text-white"/></button>
            </div>
          </div>

          <div class="flex justify-center" v-if="loading">
            <LoadingIcon class="scale-50 text-center"/>
          </div>

          <div v-if="subscribeToSubscription">
            <CreateSubscription
              :paymentMethods="paymentMethods"
              :subscription="subscription"
              @closeModel="() => showModal = false"
              @refresh="handleShowModal"
            />
          </div>

          <div v-if="initializeCustomer" class="space-y-2">
            <h2
              class="min-w-max"
            >First, add a credit card to your account</h2>
            <CreateCustomer
              @closeModal="() => showModal = false"
              @customerCreated="handleShowModal"
            />
          </div>

          <div v-if="alreadySubscribed && !loading">
            <h2 class="p-4 text-lg">You are already subscribed to {{ subscription.name }}!</h2>
          </div>

          <div v-if="!forYourUserType">
            <h2 class="p-4 text-lg">You are not the right type of user to use this feature.</h2>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Wraps a component and when clicked opens a modal
 * 3/21/23
 */

import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { payment } from '@nicballesteros/golf-guru-client/';

import subscriptions from '@/pricing/subscriptions.json';
import cardBrands from '@/pricing/cardBrands.json';

import NetworkError from '@/error/NetworkError';
import ClientError from '@/error/ClientError';

import LoadingIcon from '@/components/LoadingIcon.vue';
import Icon from '@/components/Icon.vue';

import CreateCustomer from './CreateCustomer.vue';
import CreateSubscription from './CreateSubscription.vue';

export default {
  name: 'SubscriptionCheckout',
  components: {
    Icon,
    LoadingIcon,
    CreateCustomer,
    CreateSubscription,
  },
  props: {
    'subscription-name': {
      type: String,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.loadingCurrentSubscriptions || this.loadingPaymentMethods;
    },
    initializeCustomer() {
      return this.forYourUserType && !this.customerInitialized && !this.loading;
    },
    subscribeToSubscription() {
      return this.forYourUserType
        && this.customerInitialized
        && !this.alreadySubscribed
        && !this.loading;
    },

    ...mapGetters([
      'isLoggedIn',
      'token',
      'user',
    ]),
  },
  methods: {
    handleShowModal() {
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }

      this.forYourUserType = this.subscription.userTypes.reduce(
        (a, b) => a || this.user.groups.includes(b),
        false,
      );

      this.showModal = true;

      if (!this.forYourUserType) {
        return;
      }

      this.getCurrentSubscriptions();
      this.getPaymentMethods();
    },
    async getCurrentSubscriptions() {
      try {
        this.loadingCurrentSubscriptions = true;

        const { data } = await payment.getSubscriptions(this.token);
        this.currentSubscriptions = data.subscriptions;

        const subs = this.currentSubscriptions.filter(
          (s) => s.type === this.subscription.id,
        );

        this.alreadySubscribed = subs.reduce((a, b) => a || b.active, false);

        this.customerInitialized = true;
        this.loadingCurrentSubscriptions = false;
      } catch (err) {
        if (err.code === 401015) {
          this.customerInitialized = false;
          this.loadingCurrentSubscriptions = false;
          return;
        }

        if (err instanceof NetworkError) {
          throw err;
        }

        throw new ClientError('Could not load your payment methods');
      }
    },
    async getPaymentMethods() {
      try {
        this.loadingPaymentMethods = true;

        const { data } = await payment.getPaymentMethods(this.token);
        this.paymentMethods = data.methods;

        this.customerInitialized = true;
        this.loadingPaymentMethods = false;
      } catch (err) {
        console.error(err);
        if (err.code === 401015) {
          this.customerInitialized = false;
          this.loadingPaymentMethods = false;
          return;
        }

        if (err instanceof NetworkError) {
          throw err;
        }

        throw new ClientError('Could not load your payment methods');
      }
    },
    formatPrice(price) {
      return (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    handleError(err) {
      throw err;
    },
  },
  setup(props) {
    const subscription = subscriptions[props.subscriptionName];

    return {
      showModal: ref(false),
      forYourUserType: ref(true),
      subscription,
      loadingCurrentSubscriptions: ref(false),
      loadingPaymentMethods: ref(false),
      customerInitialized: ref(false),
      alreadySubscribed: ref(false),
      paymentMethods: ref(undefined),
      currentSubscriptions: ref(undefined),
      cardBrands,
    };
  },
};
</script>
