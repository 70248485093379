<template>
  <div>
    <div class="flex items-center gap-4 select-none">
      <div
        class="flex gap-2 hover:cursor-pointer max-w-fit items-center"
        @click="() => showAll = !showAll"
      >
        <input type="checkbox" v-model="showAll"/>
        <p>Show All Teams</p>
      </div>
    </div>

    <div
      class="bg-whitesmoke80 rounded-lg text-fringe border-2 border-whitesmoke60"
      data-cy="teams-table"
    >
      <div
        class="flex font-bold justify-around items-center md:text-lg sm:text-base text-sm px-4 py-1
          border-b-2 border-whitesmoke60"
        data-cy="teams-table-header"
      >
        <p class="flex-1 text-left">Name</p>
        <div class="flex flex-1 justify-around items-center">
          <p class="sm:block hidden flex-1 max-w-fit">Average Score</p>
          <p class="sm:block hidden flex-1 max-w-fit">Average Adj. Score</p>
          <p class="sm:hidden flex-1 max-w-fit overline">Score</p>
          <p class="sm:hidden flex-1 max-w-fit overline">Adj. Score</p>
        </div>
      </div>

      <div
        v-if="teamsToShow.length === 0"
        class="bg-whitesmoke100 rounded-b-lg py-2"
      >
        <p class="text-center">No Teams</p>
      </div>

      <div v-for="(team, index) in teamsToShow" :key="team.id">
        <router-link :to="`/team/${team.id.substring(5)}`">
          <div
            class="px-4 py-2 bg-whitesmoke100 flex hover:font-bold"
            :class="index === teamsToShow.length - 1 ? 'rounded-b-lg' : ''"
          >
            <div class="flex-1 flex items-center gap-2" >
              <p
                class="font-bold"
              >{{ team.ranking !== -1 ? team.ranking : 'N/A'}}</p>
              <img
                v-if="showImg[index]"
                :src="getLogoPath(team.school, team.logoExt)"
                @error="handleImgError(index)"
                class="rounded-full w-8 h-8"
              />
              <img
                v-if="!showImg[index]"
                src="@/assets/logo.png"
                class="rounded-full w-8 h-8"
              >
              <p class="text-left">{{ team.name }}</p>
            </div>
            <div class="flex-1 flex">
              <p class="flex-1">{{ team.average.score.toFixed(2) }}</p>
              <p class="flex-1">{{ team.average.adjustedScore.toFixed(2) }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Show the team rankings for a season
 * 3/1/23
 */

export default {
  name: 'HomeSeasonsTeams',
  props: {
    teams: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLogoPath(schoolId, ext) {
      const id = schoolId.split(':')[1];

      return `https://${process.env.VUE_APP_ASSET_DOMAIN}/images/schools/${id}.${ext}`;
    },
    handleImgError(index) {
      this.showImg[index] = false;
    },
  },
  computed: {
    teamsToShow() {
      const teams = structuredClone(this.teams);
      teams.sort((a, b) => {
        if (a.ranking > 0 && b.ranking > 0) {
          return a.ranking - b.ranking;
        }

        if (a.ranking === -1) {
          return 1;
        }

        return -1;
      });

      if (!this.showAll) {
        return teams.slice(0, 10);
      }

      return teams;
    },
  },
  data() {
    return {
      showImg: this.teams.map(() => true),
      showScores: true,
      showAll: false,
    };
  },
};
</script>
