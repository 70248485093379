import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage/index.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutPage.vue'),
  },
  {
    path: '/contact',
    name: 'contact-us',
    component: () => import('../views/ContactPage.vue'),
    alias: '/support',
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage/index.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage/index.vue'),
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/ConfirmPage/index.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard/index.vue'),
  },
  {
    path: '/season/:season',
    name: 'season',
    component: () => import('../views/SeasonPage/index.vue'),
  },
  {
    path: '/team/:team',
    name: 'team',
    component: () => import('../views/TeamPage/index.vue'),
  },
  {
    path: '/newteam',
    name: 'new-team',
    component: () => import('../views/NewTeamPage/index.vue'),
  },
  {
    path: '/event/:event',
    name: 'event',
    component: () => import('../views/EventPage/index.vue'),
  },
  {
    path: '/newevent',
    name: 'new-event',
    component: () => import('../views/NewEventPage/index.vue'),
  },
  {
    path: '/player/:id',
    name: 'player',
    component: () => import('../views/PlayerPage/index.vue'),
  },
  {
    path: '/coach/:id',
    name: 'coach',
    component: () => import('../views/CoachPage/index.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/ForgotPasswordPage/index.vue'),
  },
  {
    path: '/course/:course',
    name: 'course',
    component: () => import('../views/CoursePage/index.vue'),
  },
  {
    path: '/newcourse',
    name: 'new-course',
    component: () => import('../views/NewCoursePage/index.vue'),
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('../views/SubscriptionPage/SubscriptionPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    return {
      top: 0,
    };
  },
});

export default router;
