<template>
  <div class="bg-fairway py-16 w-full">
    <div class="sm:w-3/4 w-[90%] mx-auto text-left">
      <div class="mb-4">
        <h1 class="font-shrikhand text-2xl text-greenFescue">Live Scores</h1>
        <p>Browse the scores of tournaments being played right now!</p>
      </div>
      <div class="bg-whitesmoke100 rounded">
        <div
          v-if="season === undefined"
          class="flex justify-center text-center m-4"
        >
          <LoadingIcon/>
        </div>
        <div
          v-if="season !== undefined"
          class="overflow-x-scroll p-4 text-gunmetal100 flex gap-2"
        >
          <div
            v-for="event in liveEvents" :key="event.key"
            class="border-2 rounded-lg border-viridian100 px-2 py-1 hover:cursor-pointer min-w-fit
              hover:scale-y-105 transition duration-300"
          >
            <router-link :to="`/event/${event.id.substring(6)}`">
            <div class="flex items-center gap-2">
              <h3 class="text-lg">{{ event.name }}</h3>
              <div class="rounded-full w-2 h-2 bg-red-500 animate-pulse"></div>
            </div>
            <p class="max-w-[12rem]">{{ courseNames[event.course] ?? '' }}</p>
            <p class="text-sm">{{ event.rounds }} Round{{ event.rounds > 1 ? 's' : '' }}</p>
            <p class="text-sm">{{ event.end !== undefined ?
              `${buildDateString(event.start)} - ${buildDateString(event.end)}` :
              buildDateString(event.start) }}</p>
            </router-link>
          </div>

          <p
            v-if="liveEvents.length === 0"
            class="text-gunmetal100 text-center"
          >No Live Events, check back later!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Shows the live events happening on the home page.
 * 2/28/23
 */

import LoadingIcon from '@/components/LoadingIcon.vue';
import { course } from '@nicballesteros/golf-guru-client';
import ClientError from '@/error/ClientError';

export default {
  name: 'HomeLiveScoring',
  components: {
    LoadingIcon,
  },
  props: {
    season: {
      required: false,
      type: Object,
    },
  },
  computed: {
    liveEvents() {
      if (this.season === undefined) {
        return [];
      }

      const events = this.season.events.filter(
        (event) => event.closed !== true && Date.parse(event.start) < Date.now(),
      );

      return events.reverse();
    },
    courseNames() {
      const courses = this.courseData.map((c) => ({
        name: c.course.data.name,
        id: c.course.data.id,
      }));

      const courseMap = {};

      courses.forEach((c) => {
        courseMap[c.id] = c.name;
      });

      return courseMap;
    },
  },
  methods: {
    async getCourses() {
      const requests = {};

      for (let i = 0; i < this.liveEvents.length; i += 1) {
        const event = this.liveEvents[i];

        if (requests[event.course] === undefined) {
          requests[event.course] = course.getCourse(event.course);
        }
      }

      const requestArray = Object.keys(requests).map((key) => requests[key]);

      try {
        const response = await Promise.all(requestArray);
        this.courseData = response.map((res) => res.data);
      } catch (err) {
        console.error(err);
        throw new ClientError('Could not retrieve an event\'s course data');
      }
    },
    buildDateString(dateString) {
      const date = new Date(dateString);

      return date.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: '2-digit',
        timeZone: 'UTC',
      });
    },
  },
  watch: {
    liveEvents() {
      this.getCourses();
    },
  },
  data() {
    return {
      courseData: [],
    };
  },
  created() {
    this.getCourses();
  },
};
</script>
