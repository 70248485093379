<template>
  <div
    class="bg-whitesmoke60 rounded text-gunmetal80"
  >Not Available</div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Shows the player rankings within the home screen
 * 3/1/23
 */
export default {
  name: 'HomeSeasonsPlayers',
};
</script>
