<template>
  <footer class="w-screen bg-gunmetal100 text-whitesmoke100">
    <div class="sm:hidden max-sm:flex text-white pt-4 justify-center">
      <img
        src="@/assets/logoSuite/GG_Primary Logo Full Color.svg"
        alt="Golf Guru Logo"
        class="w-32 h-16 bg-whitesmoke100 rounded-lg px-2"
      >
    </div>
    <div class="flex justify-around items-start py-2">
      <div class="flex-1 sm:flex max-sm:hidden text-white my-auto justify-center">
        <img
          src="@/assets/logoSuite/GG_Primary Logo Full Color.svg"
          alt="Golf Guru Logo"
          class="w-32 h-16 bg-whitesmoke100 rounded-lg px-2"
        >
      </div>
      <div class="flex-1 text-whitesmoke60">
        <h2 class="text-lg font-shrikhand text-whitesmoke60">Support</h2>

        <div class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100">
          <router-link to="/contact"><a>Contact Us</a></router-link>
        </div>
        <div class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100">
          <router-link to="/faq"><a>FAQ</a></router-link>
        </div>
        <div class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100">
          <router-link to="/about"><a>About</a></router-link>
        </div>

      </div>
      <div class="flex-1 text-whitesmoke60">
        <h2 class="text-lg font-shrikhand text-whitesmoke60">Links</h2>

        <div class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100">
          <router-link to="/login"><a>Login</a></router-link>
        </div>
        <div class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100">
          <router-link to="/register"><a>Register</a></router-link>
        </div>
        <a
          href="/static/privacy.html"
          class="max-w-fit mx-auto hover:cursor-pointer hover:text-whitesmoke100"
        >Privacy Policy</a>

      </div>
    </div>
  </footer>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description This component is the footer at the bottom of all pages.
 */

export default {
  name: 'FooterComponent',
};
</script>
