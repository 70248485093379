<template>
  <div
    class="w-full sm:relative text-whitesmoke100 max-sm:bg-home max-sm:bg-[length:200%]
    max-sm:bg-[left_calc(50%)_top_calc(0%)] max-sm:bg-no-repeat"
  >
    <img
      src="@/assets/homePageBackground.jpg"
      class="hidden sm:block sm:w-screen object-contain sm:brightness-[95%]"
    >
    <HeaderComponent :showBackground="false" class="sm:absolute sm:top-0 sm:left-0 w-screen"/>
    <div
      class="w-3/4 mx-auto max-sm:py-8 min-[395px]:pt-[50%] sm:pt-0 sm:absolute sm:text-left
      lg:w-1/3 md:w-1/2 md:top-1/3 sm:left-[12%] sm:top-1/4 md:top-1/2 sm:w-1/2"
    >
      <h1
        class="font-shrikhand lg:text-5xl md:text-3xl text-2xl
        drop-shadow-[0_0.15rem_0.15rem_rgba(0,0,0,0.8)]"
      >The Next Generation Golf Statistics App</h1>
      <p
        class="text-left lg:text-lg mx-auto drop-shadow-[0_0.15rem_0.15rem_rgba(0,0,0,0.8)]"
      >Golf Guru provides more accurate comparisons between teams by factoring in Golf
      Course Difficulty. Our GolfGuru Algorithm calculates adjusted scores for each team and
      player.</p>
      <div class="flex gap-2 max-sm:mt-4 sm:mt-2">
        <button
          class="rounded-full bg-viridian100 px-6 py-2 text-sm lg:text-base uppercase
          hover:bg-viridian80 px-4 py-2"
          @click="() => $router.push('/login')"
        >Log In</button>
        <button
          class="rounded-full bg-viridian100 lg:px-6 lg:py-2 text-sm lg:text-base uppercase
          hover:bg-viridian80 px-4 py-2"
          @click="() => $router.push('/#seasons')"
        >Browse</button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description The top of the home page where a user lands when they access the webpage
 * 2/27/23
 *
 * Adapted from design from Ashton Statz
 */

import Header from '@/components/Header.vue';

export default {
  name: 'HomeLanding',
  components: {
    HeaderComponent: Header,
  },
};
</script>
