<template>
  <FormKit
    type="select"
    :label="label"
    :options="options"
    :classes="{
      inner: '$reset flex items-center sm:font-bold hover:cursor-pointer',
      outer: 'max-w-fit',
      wrapper: 'max-w-fit',
      option: `${textStyle} ${textSize} ${optionStyle} $remove:text-gray-700 hover:cursor-pointer`,
      input: `min-w-fit $remove:pl-3 $remove:text-base $remove:text-gray-700 ` +
        `$remove:placeholder-gray-400 hover:cursor-pointer pr-8 ${textStyle} ${textSize}`,
    }"
    :select-icon-class="`${textStyle} scale-[1.4] $remove:-ml-[1.5em] -ml-6`"
    select-icon="caretDown"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @input="(value) => $emit('input', value)"
  />
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Styleized dropdown menu for titles
 * 2/28/23
 */

export default {
  name: 'StyledSelect',
  props: {
    label: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    textStyle: {
      type: String,
      default: 'text-gunmetal100',
      required: false,
    },
    textSize: {
      type: String,
      default: 'md:text-4xl text-sm sm:text-xl',
      required: false,
    },
    optionStyle: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>
