<template>
  <FormKit
    type="form"
    @submit="(data) => createCustomer(data.multiStep)"
    :actions="false"
  >
    <FormKit
      type="multi-step"
      :allow-incomplete="false"
      name="multiStep"
    >
      <FormKit
        type="step"
        label="Phone"
        name="phoneObject"
      >
        <div class="flex items-center gap-2">
          <img
            src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/us.svg" alt="US"
            class="w-8 h-8"
          />
          <FormKit
            type="tel"
            label="Phone Number"
            placeholder="xxxxxxxxxx"
            validation="required|matches:/^[0-9]{10}$/"
            :validation-messages="{
              matches: 'Phone number must be a 10 digit number with no delimiters',
            }"
            validation-visibility="dirty"
            name="phone"
          />
        </div>
      </FormKit>
      <FormKit
        type="step"
        label="Payment Method"
        name="card"
      >
        <StripeCreditCardForm/>
      </FormKit>
      <FormKit
        type="step"
        label="Billing Address"
        name="address"
      >
        <BillingAddressForm />
        <template v-slot:stepNext>
          <FormKit type="submit"/>
        </template>
      </FormKit>
    </FormKit>
  </FormKit>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Allows a user to create a billing profile
 * 3/21/23
 */

import { payment } from '@nicballesteros/golf-guru-client';
import { mapGetters } from 'vuex';

import NetworkError from '@/error/NetworkError';
import ClientError from '@/error/ClientError';

import StripeCreditCardForm from './StripeCreditCardForm.vue';
import BillingAddressForm from './BillingAddressForm.vue';

export default {
  name: 'CreateCustomer',
  emits: ['closeModal', 'customerCreated'],
  components: {
    BillingAddressForm,
    StripeCreditCardForm,
  },
  computed: {
    ...mapGetters([
      'token',
    ]),
  },
  methods: {
    async createCustomer({ card, phoneObject, address }) {
      try {
        const { phone } = phoneObject;

        const [createPaymentMethodResponse] = await Promise.all([
          this.$stripe.createPaymentMethod({
            type: 'card',
            card: card.card.cardElement,
            billing_details: {
              name: card.cardholderName,
              address: {
                city: address.city,
                country: address.country,
                line1: address.street,
                line2: address.street2,
                postal_code: address.zipCode,
                state: address.state,
              },
            },
          }),
          payment.createCustomer(this.token, {
            ...address,
          }, phone),
        ]);

        const { paymentMethod } = createPaymentMethodResponse;

        await payment.attachPaymentCard(this.token, paymentMethod.id);

        this.$emit('customerCreated');
      } catch (err) {
        console.error(err);
        this.$emit('closeModal');

        if (err instanceof NetworkError) {
          throw err;
        }

        throw new ClientError('Could not create a subscription');
      }
    },
  },
};
</script>
