<template>
  <div class="flex items-center" @click="handleIconClick">
    <span
      class="material-icons transition duration-500 ease-linear"
      :class="`${active ? 'rotate-90' : 'rotate-0'} ${iconClass}`"
    >{{ icon }}</span>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @module /src/components/Icons
 * @description This component is an icon wrapper component that can display a material
 * icon and give it a transition.
 */

export default {
  name: 'IconComponent',
  props: {
    icon: {
      required: true,
      type: String,
    },
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
    link: {
      required: false,
      type: String,
    },
    iconClass: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    handleIconClick() {
      if (this.link) {
        this.$router.push(this.link).catch(() => {});
      }
    },
  },
};
</script>
