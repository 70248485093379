<template>
  <div class="">
    <div
      v-if="numberOfEventsOptions.length > 1"
      class="flex items-center justify-end gap-2 mb-2"
      data-cy="number-of-events-selector"
    >
      <p class="text-sm">Number of Events:</p>
      <FormKit
        type="select"
        class="min-w-fit"
        :options="numberOfEventsOptions"
        input-class="mr-2"
        outer-class="$remove:mb-4"
        inner-class="bg-whitesmoke100 text-gunmetal100"
        v-model="numberOfEventsToShow"
      />
    </div>

    <div
      class="bg-whitesmoke80 rounded-lg text-gunmetal100 border-2 border-whitesmoke60"
      data-cy="events-table"
    >
      <div
        class="flex font-bold justify-around md:text-lg sm:text-base text-sm px-4 py-1
          border-b-2 border-whitesmoke60"
      >
        <p class="flex-2 sm:flex-1 text-left">Name</p>
        <div class="hidden sm:flex flex-1">
          <p class="flex-1">Rounds</p>
          <p class="flex-1">Start Date</p>
        </div>
        <p class="flex-1 sm:hidden">Rounds</p>
        <p class="flex-1 sm:hidden">Start</p>
      </div>
      <div
        v-if="events.length === 0"
        class="bg-whitesmoke100 rounded-b-lg py-2"
      >
        <p class="text-center">No Events</p>
      </div>
      <div
        v-for="(event, index) in eventsToShow"
        :key="event.id"
        class="px-4 py-2 bg-whitesmoke100 flex sm:text-base text-sm md:text-lg"
        :class="index === eventsToShow.length - 1 ? 'rounded-b-lg' : ''"
      >
        <div class="flex flex-2 sm:flex-1 items-center gap-2" >
          <router-link :to="`/event/${event.id.substring(6)}`">
            <div class="flex items-center gap-1 hover:cursor-pointer hover:font-bold">
              <p class="text-left max-w-fit">{{ event.name }}</p>
              <Icon icon="open_in_new" iconClass="text-sm"/>
            </div>
          </router-link>

          <div
            v-if="!event.closed"
            class="rounded-full px-2 py-0.5 border-2 border-viridian80 flex gap-1 items-center"
          >
            <p class="text-sm">LIVE</p>
            <div class="rounded-full w-2 h-2 animate-pulse bg-red-500"></div>
          </div>
        </div>

        <div class="flex-1 hidden sm:flex text-center items-center">
          <p class="flex-1">{{ event.rounds }}</p>
          <p
            class="flex-1 sm:block hidden"
          >{{ buildDateString(event.start, 'md') }}</p>
        </div>

        <p class="flex-1 sm:hidden">{{ event.rounds }}</p>

        <p
          class="flex-1 sm:hidden"
        >{{ buildDateString(event.start, 'sm') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Shows the events within the home screen
 * 3/1/23
 */

import Icon from '@/components/Icon.vue';

export default {
  name: 'HomeSeasonsEvents',
  components: {
    Icon,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    eventsToShow() {
      return this.events.slice(0, this.numberOfEventsToShow);
    },
    numberOfEventsOptions() {
      const ret = [];

      for (let i = 10; i < this.events.length; i += 10) {
        ret.push(i);
      }

      ret.push(this.events.length);

      return ret;
    },
  },
  methods: {
    buildDateString(str, size) {
      const date = new Date(str);

      if (size === 'md') {
        return date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      }

      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: '2-digit',
      });
    },
    navigateToEvent(eventId) {
      const id = eventId.replace(':', '/');

      this.$router.push(`/${id}/`);
    },
  },
  data() {
    return {
      numberOfEventsToShow: 10,
    };
  },
};
</script>
