<template>
  <div class="bg-fringe py-16 w-full">
    <div class="sm:w-3/4 w-[90%] mx-auto text-left font-lato">
      <h1 class="font-russo font-normal text-2xl text-greenFescue">Seasons</h1>
      <p>Golf Guru provides historical event data completely free of charge!
      Begin browsing our historical data.</p>
      <div class="mt-4 flex font-bold justify-end gap-2 items-center">
        <div class="flex" @click="() => handleStateSelect('IN')">
          <StyledRadioButton :selected="state === 'IN'"/>
          <p class="md:text-lg">State</p>
        </div>
        <div class="flex" @click="() => handleStateSelect('COLLEGE')">
          <StyledRadioButton :selected="state === 'COLLEGE'"/>
          <p class="md:text-lg">College</p>
        </div>
      </div>
      <div class="mt-2 flex">
        <div class="">
          <StyledSelect
            v-if="!loadingSeasons"
            v-model="seasonId"
            :options="seasonOptions"
            textStyle="text-whitesmoke100 font-shrikhand"
            optionStyle="bg-viridian100"
            textSize="text-sm sm:text-2xl md:text-3xl"
          />
        </div>
        <div class="flex-1"></div>
        <div v-if="state !== 'COLLEGE'">
          <StyledSelect
            :options="stateOptions"
            v-model="state"
            textStyle="text-whitesmoke100 font-shrikhand"
            optionStyle="bg-viridian100"
            textSize="text-sm sm:text-2xl md:text-3xl"
          />
        </div>
      </div>
      <div
        v-if="loadingSeasons || loadingThisSeason"
        class="rounded-full bg-whitesmoke100 text-center max-w-fit mx-auto scale-[.5]"
      >
        <LoadingIcon/>
      </div>
      <div
        v-if="!loadingSeasons && !loadingThisSeason"
        class="w-full text-center md:text-xl"
      >
        <div class="flex w-full">
          <div class="flex-1">
            <button
              class="rounded-full py-2 px-4 text-base lg:text-lg font-thin"
              :class="tab === 'teams' ?
                'border-0 bg-whitesmoke100 text-viridian100 hover:text-viridian80' :
                'border-2 border-whitesmoke100 hover:text-whitesmoke80'"
              @click="() => tab = 'teams'"
            >Team Rankings</button>
          </div>
          <div class="flex-1">
            <button
              class="rounded-full py-2 px-4 text-base lg:text-lg
                font-thin"
              :class="tab === 'events' ?
                'border-0 bg-whitesmoke100 text-viridian100 hover:text-viridian80' :
                'border-2 border-whitesmoke100 hover:text-whitesmoke80'"
              @click="() => tab = 'events'"
            >Season Events</button>
          </div>
          <div class="flex-1">
            <button
              class="rounded-full py-2 px-4 text-base lg:text-lg
                font-thin hover:text-gunmetal80"
              :class="tab === 'players' ?
                'border-0 bg-whitesmoke100 text-viridian100 hover:text-viridian80' :
                'border-2 border-whitesmoke100 hover:text-whitesmoke80'"
              @click="() => tab = 'players'"
            >Player Rankings</button>
          </div>
        </div>
        <div class="mt-4">
          <Teams v-if="tab === 'teams'" :teams="seasonData.teams"/>
          <Events v-if="tab === 'events'" :events="seasonData.events"/>
          <Players v-if="tab === 'players'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Shows the seasons in the system on the home page.
 * 2/28/23
 */

import { mapGetters } from 'vuex';
import { state, season as seasonApi } from '@nicballesteros/golf-guru-client';

import ClientError from '@/error/ClientError';

import StyledSelect from '@/components/StyledSelect.vue';
import StyledRadioButton from '@/components/StyledRadioButton.vue';
import LoadingIcon from '@/components/LoadingIcon.vue';

import Teams from './Teams.vue';
import Events from './Events.vue';
import Players from './Players.vue';

export default {
  name: 'HomeSeasons',
  components: {
    StyledSelect,
    StyledRadioButton,
    LoadingIcon,
    Teams,
    Events,
    Players,
  },
  computed: {
    stateOptions() {
      if (this.state === 'NCAA') {
        return ['NCAA'];
      }

      return [
        {
          label: 'Indiana',
          value: 'IN',
        },
      ];
    },
    ...mapGetters([
      'isLoggedIn',
      'token',
    ]),
  },
  methods: {
    async fetchSeason() {
      try {
        this.loadingThisSeason = true;
        const { data } = await seasonApi.getSeason(this.seasonId);

        this.seasonData = data.season;

        this.seasonData.teams.sort((a, b) => {
          if (b.ranking === 0) {
            return -1;
          }

          if (a.ranking === 0) {
            return 1;
          }

          if (a.ranking < b.ranking) {
            return -1;
          }

          if (a.ranking > b.ranking) {
            return 1;
          }

          return 0;
        });

        this.seasonData.events.sort((a, b) => {
          const time1 = new Date(a.start).getTime();
          const time2 = new Date(b.start).getTime();

          if (time1 < time2) {
            return 1;
          }

          if (time1 > time2) {
            return -1;
          }

          return 0;
        });

        this.$emit('season', this.seasonData);

        this.loadingThisSeason = false;
      } catch (err) {
        console.error(err);
        this.loadingThisSeason = false;
        throw new ClientError('Could not load the season data');
      }
    },
    async fetchStateSeasons() {
      try {
        this.loadingSeasons = true;
        const { data } = await state.getState(`state:${this.state}`);

        const seasons = data.state.seasons.sort((a, b) => {
          if (a.priority > b.priority) {
            return -1;
          }

          if (a.priority < b.priority) {
            return 1;
          }

          return 0;
        });

        this.seasonOptions = seasons.map((season) => {
          const name = season.name.split(' ');

          return {
            label: `${name[0]} ${name[2]}`,
            value: season.id,
          };
        });

        this.seasonId = this.seasonOptions[0].value;
        this.fetchSeason();

        this.loadingSeasons = false;
      } catch (err) {
        console.error(err);
        this.loadingSeasons = false;
        throw new ClientError('Could not retreive state data');
      }
    },
    handleStateSelect(value) {
      this.state = value;
      this.fetchStateSeasons();
    },
  },
  watch: {
    seasonId() {
      this.fetchSeason();
    },
    state() {
      this.fetchStateSeasons();
    },
  },
  data() {
    return {
      state: 'IN',
      seasonOptions: [],
      seasonId: '',
      loadingSeasons: false,
      loadingThisSeason: false,
      seasonData: {},
      tab: 'teams',
    };
  },
  created() {
    const [subdomain] = window.location.host.split('.');

    if (subdomain === 'college') {
      this.state = 'COLLEGE';
    } else {
      this.state = 'IN';
    }

    this.fetchStateSeasons();
  },
};
</script>
