<template>
  <div>
    <FormKit
      type="text"
      name="cardholderName"
      label="Cardholder Name"
      validation="required"
      validation-label="Name"
    />
    <FormKit
      :type="stripeInput"
      label="Card"
      name="card"
      validation="required|emptyValidation|cardValidation"
      :validation-rules="{
        cardValidation,
        emptyValidation,
      }"
      validation-visibility="live"
      :validation-messages="{
        cardValidation: 'Your card is imcomplete',
        emptyValidation: 'Card is required'
      }"
      validation-label="Card"
    />
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Adds StripeInput for Form functionality
 * 10/13/23
 */

import { createInput } from '@formkit/vue';

import StripeInput from '../CustomFormKit/StripeCardInput.vue';

export default {
  name: 'StripeCreditCardForm',
  methods: {
    cardValidation({ value }) {
      if (value === undefined) {
        return false;
      }

      if (value.validation === false) {
        return false;
      }

      return true;
    },
    emptyValidation({ value }) {
      if (value === undefined) {
        return false;
      }

      if (value.empty === true) {
        return false;
      }

      return true;
    },
  },
  data() {
    return {
      stripeInput: createInput(StripeInput),
    };
  },
};
</script>
