<template>
  <div>
    <span
      class="formkit-decorator block relative h-5 w-5 mr-2 rounded bg-white bg-gradient-to-b
        from-transparent to-gray-200 ring-1 ring-gray-400 rounded-full"
      :class="buttonClass"
      aria-hidden="true"
    >
      <span
        class="formkit-decorator-icon flex p-[3px] w-full h-full absolute top-1/2 left-1/2
          -translate-y-1/2 -translate-x-1/2 w-5 p-[5px] formkit-icon"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <circle fill="currentColor" cx="16" cy="16" r="16"></circle>
        </svg>
      </span>
    </span>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros using FormKit styling
 * @description Renders a nice looking radio button
 * 3/23/23
 */

export default {
  name: 'StylizedRadioButton',
  props: {
    selected: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  computed: {
    buttonClass() {
      if (this.selected) {
        return 'text-blue-500 ring-blue-500';
      }

      return 'text-transparent';
    },
  },
};
</script>
