<template>
  <div id="pricing" class="bg-fairway py-16">
    <div class="sm:w-3/4 w-[90%] mx-auto text-left">
      <div class="mb-4">
        <h1 class="font-shrikhand text-2xl text-greenFescue">Pricing</h1>
        <p>Golf Guru provides you with many different subscription plans to suit your statistics
        needs.</p>
      </div>

      <SubscriptionCheckout
        class="flex rounded-lg items-center bg-whitesmoke100 text-gunmetal100 p-4 m-4
          hover:m-0 hover:cursor-pointer ease-in duration-300"
        subscription-name="player-recruitment"
        v-slot="slotProps"
      >
        <div class="flex-1">
          <h2 class="text-xl font-bold">Player Recruitment Package</h2>
          <p>Maximize your exposure with Golf Guru's comprehensive data.
             Share your player report with college recruiters.
              Plus, easily upload files to enhance your recruitment report.</p>
        </div>
        <div class="flex-1 text-right">
          <p class="text-2xl">{{ slotProps.price }} / month</p>
        </div>
      </SubscriptionCheckout>

      <SubscriptionCheckout
        class="flex rounded-lg items-center bg-whitesmoke100 text-gunmetal100 p-4 m-4
          hover:m-0 hover:cursor-pointer ease-in duration-300"
        subscription-name="live-scoring"
        v-slot="slotProps"
      >
        <div class="flex-1">
          <h2 class="text-xl font-bold">Live Scoring Pass</h2>
          <p>Stay connected and informed with real-time event scores.
             Perfect for keeping your family updated on your performance
              while you're on the course.</p>
        </div>
        <div class="flex-1 text-right">
          <p class="text-2xl">{{ slotProps.price }} / month</p>
        </div>
      </SubscriptionCheckout>
    </div>
  </div>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description Shows the pricing data for the app.
 * 2/28/2023
 */

import SubscriptionCheckout from '@/components/SubscriptionCheckout/SubscriptionCheckout.vue';

export default {
  name: 'HomePricing',
  components: {
    SubscriptionCheckout,
  },
};
</script>
