<template>
  <transition appear name="profile-slide">
    <div
      class="absolute top-16 right-0 bg-whitesmoke100 text-gunmetal100 rounded-bl border-gunmetal60
        border-l-2 border-b-2 w-48 z-50"
      >
      <div
        v-if="isLoggedIn"
        class="p-4 flex-col space-y-2 items-center"
      >
        <div class="flex gap-2 justify-center">
          <Icon icon="account_circle"/>
          <div>
            <h3>{{ user.name }}</h3>
          </div>
        </div>
        <hr/>
        <div>
          <router-link to="/dashboard">
            <a
              @click="dashboard"
              class="hover:cursor-pointer hover:font-bold"
            >My Dashboard</a>
          </router-link>
        </div>
        <div>
          <router-link to="/subscriptions">
            <a class="hover:font-bold">My Subscriptions</a>
          </router-link>
        </div>
        <p
          @click="signOut"
          class="hover:cursor-pointer hover:font-bold"
        >Sign Out</p>
      </div>
      <div
        v-if="!isLoggedIn"
        class="flex-col space-y-2 p-4"
      >
        <h3 class="title">Not signed in</h3>
        <p
          @click="login"
          class="hover:cursor-pointer hover:font-bold"
        >Login</p>
        <p
          @click="register"
          class="hover:cursor-pointer hover:font-bold"
        >Register</p>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * @author Nic Ballesteros
 * @description The profile dropdown widget
 */

import { mapActions, mapGetters } from 'vuex';

import Icon from '@/components/Icon.vue';

export default {
  name: 'ProfileComponent',
  components: {
    Icon,
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'user',
    ]),
  },
  methods: {
    signOut() {
      this.logout();
      this.$router.push('/login');
    },
    dashboard() {
      this.$router.push('/dashboard');
    },
    login() {
      this.$router.push('/login');
    },
    register() {
      this.$router.push('/register');
    },
    ...mapActions([
      'logout',
    ]),
  },
};
</script>

<style scoped>
  @keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    80% {
      transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(1)
    }
  }

  @keyframes growUp {
    0% {
      transform: scaleY(1)
    }
    20% {
      transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(0)
    }
  }

  .profile-slide-enter-active {
    animation: growDown .3s ease-in-out forwards;
    transform-origin: top center;
  }

  .profile-slide-leave-to {
    animation: growUp .3s ease-in-out forwards;
    transform-origin: top center;
  }
</style>
